var $ = require('jquery');

var symbolCollapsed = '\u25BA ';
var symbolExpanded = '\u25BC ';

function makeCollapsible() {
	var fieldset = $(this);
	var id = fieldset.attr('id');
	if (id) {
		var legend = $('#' + id + ' legend');
		var html = legend.html();
		if (fieldset.hasClass('collapsible-default')) {
			legend.html(symbolExpanded + html);
			legend.click(function () {
				toggle(id);
			});
		}
		else if (fieldset.hasClass('collapsible-collapsed')) {
			legend.html(symbolCollapsed + html);
			legend.click(function () {
				toggle(id);
			});
			hideSiblings(id);
		}
	}
}

function removeSymbol(input) {
	var posExpanded = input.indexOf(symbolExpanded);
	if (posExpanded != -1) {
		return input.substr(posExpanded + 1);
	}
	var posCollapsed = input.indexOf(symbolCollapsed);
	if (posCollapsed != -1) {
		return input.substr(posCollapsed + 1);
	}
	return input;
}

function showSiblings(id) {
	var siblings = $('#' + id + ' > *:not(legend)');
	siblings.show();
}

function toggle(id) {
	var fieldset = $('#' + id);
	var legend = $('#' + id + ' > legend');
	if (fieldset.hasClass('collapsible-collapsed')) {
		legend.html(symbolExpanded + removeSymbol(legend.html()));
		fieldset.addClass('collapsible-default');
		fieldset.removeClass('collapsible-collapsed');
		showSiblings(id);
	}
	else if (fieldset.hasClass('collapsible-default')) {
		legend.html(symbolCollapsed + removeSymbol(legend.html()));
		fieldset.addClass('collapsible-collapsed');
		fieldset.removeClass('collapsible-default');
		hideSiblings(id);
	}
}

function show(id) {
	var fieldset = $('#' + id);
	var legend = $('#' + id + ' > legend');
	if (fieldset.hasClass('collapsible-collapsed')) {
		legend.html(symbolExpanded + removeSymbol(legend.html()));
		fieldset.addClass('collapsible-default');
		fieldset.removeClass('collapsible-collapsed');
		showSiblings(id);
	}
}

function hide(id) {
	var fieldset = $('#' + id);
	var legend = $('#' + id + ' > legend');
	if (fieldset.hasClass('collapsible-default')) {
		legend.html(symbolCollapsed + removeSymbol(legend.html()));
		fieldset.addClass('collapsible-collapsed');
		fieldset.removeClass('collapsible-default');
		hideSiblings(id);
	}
}

function hideSiblings(id) {
	var siblings = $('#' + id + ' > *:not(legend)');
	siblings.hide();
}

function CollapsibleFieldset() {
	$.each($('fieldset.collapsible-default'), makeCollapsible);
	$.each($('fieldset.collapsible-collapsed'), makeCollapsible);
}

module.exports = {
	init: function () {
		new CollapsibleFieldset();
	},
	toggle: function (id) {
		toggle(id);
	},
	show: function (id) {
		show(id);
	},
	hide: function (id) {
		hide(id);
	}
};
