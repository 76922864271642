const Chosen = require('./common/chosen');
const CollapsibleFieldset = require('./common/collapsiblefieldset');
const CanonicalPhonenumber = require('./common/canonical-phonenumber');

const Freigabe = require('./wr/freigabe');
const Datenschutz = require('./wr/datenschutz');

function Application() {
	Chosen.init();
	CollapsibleFieldset.init();
	CanonicalPhonenumber.init();

	return {
		Freigabe: Freigabe,
		Datenschutz: Datenschutz
	}
}

module.exports = Application;
