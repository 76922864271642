var $ = require('jquery');

function Freigabe() {

	function setFree(jquery) {
		jquery.removeClass('selectable');
		jquery.addClass('free');
		jquery.unbind('click');
		jquery.find('span').attr('title', 'freigegeben');
		jquery.find("input[name^='status_']").val(1);
		jquery.bind('click', function () {
			setSelectable($(this));
		});
	}

	function setSelectable(jquery) {
		jquery.removeClass('free');
		jquery.addClass('selectable');
		jquery.unbind('click');
		jquery.find('span').attr('title', 'nicht freigegeben');
		jquery.find("input[name^='status_']").val(0);
		jquery.bind('click', function () {
			setFree($(this));
		});
	}

	function registerEvents() {
		$('.freigabe_kalender .selectable').click(function () {
			setFree($(this));
		});
		$('.freigabe_kalender .free').click(function () {
			setSelectable($(this));
		});
	}

	$(document).ready(function () {
		registerEvents();
	});
}

module.exports = Freigabe;

