const $ = require('expose-loader?exposes=jQuery!jquery');
require('chosen-js');

const chosenSelectOptions = {
	no_results_text: 'Keine Ergebnisse gefunden:'
};
const chosenSelectDeselectOptions =  {
	no_results_text: 'Keine Ergebnisse gefunden:',
	allow_single_deselect: true
};

const chznSelect = '.chzn-select';
const chznSelectDeselect = '.chzn-select-deselect';

let observer = undefined;
let initialized = false;

function findForm(element) {
	if (element.parentNode === document.documentElement) {
		return undefined;
	}
	if (element.parentNode instanceof HTMLFormElement || element.parentNode.classList.contains('form')) {
		return element.parentNode;
	} else {
		return findForm(element.parentNode);
	}
}

function triggerUpdated(element) {
	$(element).chosen('chosen:updated');
}

function initGlobal() {
	if (!initialized) {
		if (MutationObserver && !observer) {
			observer = new MutationObserver(function(mutations) {
				mutations.forEach(function(mutation) {
					mutation.addedNodes.forEach((node) => {
						if (node instanceof HTMLElement) {
							node.querySelectorAll(chznSelect).forEach(
								it => setTimeout(() => {
									$(it).chosen(chosenSelectOptions)
								})
							);
							node.querySelectorAll(chznSelectDeselect).forEach(
								it => setTimeout(() => {
									$(it).chosen(chosenSelectDeselectOptions)
								})
							);
						}
					})
				});
			});
			observer.observe(document.documentElement, { childList: true, subtree: true });
		}

		document.addEventListener('zwe:offcanvas:desktop', function (event) {
			setTimeout(function () {
				recreate();
			}, 600);	// timeout needed because of animation
		});

		/* does not work correctly
		document.addEventListener('click', function (e) {
			if ((e.target instanceof HTMLButtonElement && e.target.type === 'reset') || (e.target instanceof HTMLInputElement && e.target.type === 'reset')) {
				const form = findForm(e.target);
				if (form) {
					form.reset();
					form.querySelectorAll('.chzn-select').forEach(triggerUpdated);
					form.querySelectorAll('.chzn-select-deselect').forEach(triggerUpdated);
					e.preventDefault();
				}
			}
		})
		*/
		initialized = true;
	}
}

function initChosen(selector) {
	if (selector) {
		document.querySelectorAll(selector).forEach(
			element =>  {
				element.querySelectorAll(chznSelect).forEach(
					chzn => $(chzn).chosen(chosenSelectOptions)
				);
				element.querySelectorAll(chznSelectDeselect).forEach(
					chzn => $(chzn).chosen(chosenSelectDeselectOptions)
				)
			}
		)
	} else {
		$(chznSelect).chosen(chosenSelectOptions);
		$(chznSelectDeselect).chosen(chosenSelectDeselectOptions);
	}
	initGlobal();
}

function destroyChosen(selector) {
	if (selector) {
		document.querySelectorAll(selector).forEach(
			element =>  {
				element.querySelectorAll(chznSelect).forEach(
					chzn => $(chzn).chosen('destroy')
				);
				element.querySelectorAll(chznSelectDeselect).forEach(
					chzn => $(chzn).chosen('destroy')
				)
			}
		)
	} {
		$(chznSelect).chosen('destroy');
		$(chznSelectDeselect).chosen('destroy');
	}
}

function recreate(selector) {
	destroyChosen(selector);
	initChosen(selector);
}

module.exports = {
	init: function () {
		$(document).ready(() => initChosen());
		window.addEventListener('resize', () => recreate());
		window.addEventListener('orientationchange', () => recreate());
	},
	recreate
};
