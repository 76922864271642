const Modal = require('./../common/modal');

function Datenschutz() {
	let isConfirmed = false;

	const form = document.querySelector('#deleteForm');
	const button = document.querySelector('#deleteAccount');
	const checkboxConfirmed = document.querySelector('input[name=bestaetigung]');

	if (form && button && checkboxConfirmed) {
		isConfirmed = checkboxConfirmed.checked;
		button.disabled = !isConfirmed;

		checkboxConfirmed.addEventListener('click', (e) => {
			isConfirmed = checkboxConfirmed.checked;
			button.disabled = !isConfirmed;
		});

		button.addEventListener('click', () => {
			Modal.create('#confirmDeleteModal', {
				onConfirm: (event) => {
					form.submit();
				}
			})
		})
	}
}

module.exports = Datenschutz;
