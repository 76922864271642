function openModal(source, targetSelector) {
	const target = targetSelector && document.querySelector(targetSelector);
	if (target) {
		openModalTarget(target);
	} else {
		console.warn(`could not find modal target ${targetSelector}`, {source});
	}
}

function openModalTarget(target, options) {
	let modalOutlet = document.body.querySelector('.modal-outlet');
	if (!modalOutlet) {
		modalOutlet = document.createElement('div', {});
		document.body.append(modalOutlet);
		modalOutlet.classList.add('modal-outlet');
	}
	modalOutlet.append(target);

	const {onConfirm, onClose} = options || {};

	function close(event) {
		target.classList.remove('open');
		if (onClose && typeof onClose === 'function') {
			onClose(event);
		}
	}

	function confirm(event) {
		target.classList.remove('open');
		if (onConfirm && typeof onConfirm === 'function') {
			onConfirm(event);
		}
	}

	const closeListener = (event) => {
		close(event);
		removeAllEventListeners();
	}

	const confirmListener = (event) => {
		confirm(event);
		removeAllEventListeners();
	}

	const escListener = (event) => {
		if (event.keyCode === 27) {
			close(event);
			removeAllEventListeners();
		}
	}

	function removeAllEventListeners() {
		target.querySelectorAll('[data-close-modal]').forEach(element => {
			element.removeEventListener('click', closeListener);
		});
		target.querySelectorAll('[data-confirm-modal]').forEach(element => {
			element.removeEventListener('click', confirmListener);
		});
		document.removeEventListener('keyup', escListener);
	}

	function open() {
		target.querySelectorAll('[data-close-modal]').forEach(element => {
			element.addEventListener('click', closeListener, {once: true});
		});

		target.querySelectorAll('[data-confirm-modal]').forEach(handler => {
			handler.addEventListener('click', confirmListener, {once: true});
		});

		document.addEventListener('keyup', escListener);

		target.classList.add('open');
	}

	open();

	return {
		open,
		target
	};
}

module.exports = {
	init: function () {
		document.addEventListener('click', function (e) {
			const dataModalTarget = e.target.attributes.getNamedItem('data-modal');
			if (dataModalTarget) {
				openModal(e.target, dataModalTarget.value);
				e.preventDefault();
			}
		})
	},
	create: function (target, options) {
		const element = typeof target === 'string' ? document.querySelector(target) : target;
		if (element) {
			return openModalTarget(element, options);
		} else {
			console.warn('could not find modal target');
		}
	}
};
