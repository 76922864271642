var $ = require('jquery');

var regexWithCountryPrefix = /^\s*(?:\+|00)(\d+)\s+[0\(]?\s*(\d+)\s*\)?[\s\-/]*([\d\s\-]+)\s*$/;
var regexWithAreaCode = /^\s*0(\d+)[\s\-/]*([\d\s\-]+)\s*$/;

function formatNumber(country, area, number) {
	var format = country ? '+' + country : '+49';
	format += ' (' + area + ')';
	format += ' ' + number;
	return format;
}

module.exports = {
	init: function () {
		$(document).ready(function () {
			$('.requires-canonical').change(function () {
				var input = $(this).val();
				if (input) {
					var match;
					if ((match = regexWithCountryPrefix.exec(input))) {
						$(this).val(formatNumber(match[1], match[2], match[3].replace(/[^\d]/g, '')));
					} else if ((match = regexWithAreaCode.exec(input))) {
						$(this).val(formatNumber(null, match[1], match[2].replace(/[^\d]/g, '')));
					}
				}
			});
		});
	}
};
